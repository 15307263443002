<template>
  <app-form
    class="flex h-full flex-col justify-between"
    data-cy-contact-form
    :submitting="status === 'pending'"
    @submit="onSubmit"
  >
    <div class="grid gap-4 lg:grid-cols-2">
      <app-form-input-text
        v-model="form.first_name"
        data-cy-first-name
        :label="$t('app.form.labels.first_name')"
        prepend-icon="ph:identification-card"
        required
      />

      <app-form-input-text
        v-model="form.last_name"
        data-cy-last-name
        :label="$t('app.form.labels.last_name')"
        prepend-icon="ph:identification-card"
        required
      />

      <app-form-input-text
        v-model="form.email"
        class="md:col-span-2"
        data-cy-email
        :label="$t('app.form.labels.professional_email')"
        prepend-icon="ph:envelope-simple"
        required
        type="email"
      />

      <app-form-input-phone-number
        v-model="form.phone_number"
        class="md:col-span-2"
        data-cy-phone-number
        :label="$t('app.form.labels.phone_number')"
        required
      />

      <app-form-input-text
        v-model="form.company"
        class="md:col-span-2"
        data-cy-company
        :label="$t('app.form.labels.company')"
        prepend-icon="ph:buildings"
        required
      />

      <app-form-input-text
        v-model="form.job_title"
        class="md:col-span-2"
        data-cy-job-title
        :label="$t('app.form.labels.job_title')"
        prepend-icon="ph:planet"
        required
      />

      <app-form-input-textarea
        v-if="options?.additional_information"
        v-model="form.additional_information"
        :auto-grow="false"
        class="md:col-span-2"
        data-cy-additional-information
        label="Comment pouvons-nous vous aider ?"
        non-required-mention
        prepend-icon="ph:handshake"
      />
    </div>

    <div :class="mobileCtaWrapperClasses">
      <app-button block size="xl" type="submit">
        {{ options?.cta_label ?? $t("app.cta.send") }}
      </app-button>

      <p
        v-if="options?.cta_subtitle"
        class="mt-2 text-center text-sm text-subtle"
      >
        {{ options.cta_subtitle }}
      </p>
    </div>
  </app-form>
</template>

<script lang="ts" setup>
import { type RouteLocationNamedRaw, type RouteLocationRaw } from "#vue-router";
import { useGrowthContactFormHistory } from "~/business-areas/growth/composables/growth-contact-form-history.hook";
import type { ContactPageFormOptions } from "~/pages/contact/_includes/composables/contact-page-content.hook";
import type { ContactFlowMetadata } from "~~/model/analytics.model";
import type { GrowthContact } from "~~/model/growth.model";

const properties = defineProps<
  {
    redirect: RouteLocationRaw;
    previous?: RouteLocationNamedRaw;
  } & ContactFlowMetadata & {
      options?: ContactPageFormOptions;
    }
>();

const emit = defineEmits<{
  submit: [];
}>();

const { me } = useEnrichedCurrentUser();
const { update, state } = useGrowthContact();
const { record, hasAlreadySubmittedWithEmail } = useGrowthContactFormHistory();

const form = reactive<GrowthContact>({
  first_name: me.value?.first_name ?? state.value?.first_name ?? undefined,
  last_name: me.value?.last_name ?? state.value?.last_name ?? undefined,
  email: me.value?.email ?? state.value?.email ?? undefined,
  phone_number:
    me.value?.phone_number ?? state.value?.phone_number ?? undefined,
  company: me.value?.company_name ?? state.value?.company ?? undefined,
  job_title: me.value?.job_title ?? state.value?.job_title ?? undefined,
  additional_information: "",
  origin: properties.origin,
  newsletter_subscribed: true,

  website_contact_form: {
    submitted: true,
    referrer: properties.previous?.path,
    origin: properties.origin,
    origin_page_id: properties.origin_page_id,
    origin_page_action: properties.origin_page_action,
  },
});

const { $analytics } = useNuxtApp();

const { execute: onSubmit, status } = useAsyncData(
  async () => {
    await timer(350);

    if (!hasAlreadySubmittedWithEmail(form.email!)) {
      $analytics.track("newsletter_subscription_created", {
        origin: properties.origin,
        origin_page_id: properties.origin_page_id,
        origin_page_action: properties.origin_page_action,
        email: form.email!,
      });
    }

    $analytics.track("call_requested", {
      origin: properties.origin,
      origin_page_action: properties.origin_page_action,
      origin_page_id: properties.origin_page_id,
    });

    update(form);
    record({
      email: form.email!,
      origin: properties.origin,
      origin_page_action: properties.origin_page_action,
      origin_page_id: properties.origin_page_id,
    });

    emit("submit");

    return navigateTo(properties.redirect);
  },
  { immediate: false },
);

const mobileCtaWrapperClasses =
  "fixed bottom-0 left-0 w-full rounded-t-lg bg-white px-6 py-4 shadow-lg lg:static lg:rounded-none lg:p-0 lg:pt-8 lg:shadow-none";
</script>
